<template>
  <!-- :class="$route.meta.showTab?'padding-bottoom-50':''" -->
  <div id="app" class="app-container">
    <router-view></router-view>
  </div>
</template>
<script>
  import TabBar from '@/components/TabBar'
  // import { getToken } from '@/utils/auth'
  // import { stringify } from 'querystring'
  export default {
    name: 'AppLayout',
    data() {
      return {
        tabbars: [
          {
            title: '活动',
            to: {
              name: 'activityList'
            },
            // icon: 'home-o'
            active: 0,
            icon: {
              active: require('@/assets/images/star1.png'),
              inactive: require('@/assets/images/star.png'),
            }
          },
          {
            title: '帮助',
            to: {
              name: 'help'
            },
            // icon: 'goods-collect-o'
            active: 1,
            icon: {
              active: require('@/assets/images/notice1.png'),
              inactive: require('@/assets/images/notice.png'),
            }
          },
          {
            title: '订单',
            to: {
              name: 'order'
            },
            // icon: 'chart-trending-o'
            active: 2,
            icon: {
              active: require('@/assets/images/order1.png'),
              inactive: require('@/assets/images/order.png'),
            }
          },
          {
            title: '会员中心',
            to: {
              name: 'user'
            },
            // icon: 'user-o'
            active: 3,
            icon: {
              active: require('@/assets/images/user1.png'),
              inactive: require('@/assets/images/user.png'),
            }
          }
        ]
      }
    },
    components: {
      TabBar
    },
    methods: {
      handleChange(v) {
        console.log('tab value:', v)
      }
      // // 获取用户权限
      // identityauthor() {
      //   debugger
      //   console.log(getToken())
      //   if (getToken()) {
      //     this.$store
      //       .dispatch('home/identityauthor')
      //       .then(data => {
      //         if (data.success) {
      //           debugger
      //           //   this.GLOBAL.USER_RIGHTS = stringify(data.data)
      //           //   sessionStorage.setItem('USER_RIGHTS', stringify(data.data))
      //           this.$root.USER_RIGHTS = data.data
      //           console.log(this.$root.USER_RIGHTS)
      //         }
      //       })
      //       .catch(e => {})
      //   }
      // }
    },
    created() {
      //   if (UNITJS.judgeHaveRight(this.GLOBAL.GOODS_MANAGEMENT)) {
      //     // 商品管理权限
      //     this.tabbars.push({
      //       title: '商品',
      //       to: {
      //         name: 'Goods'
      //       },
      //       // icon: 'goods-collect-o'
      //       active: 1,
      //       icon: {
      //         active: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive11.png',
      //         inactive: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive1.png'
      //       }
      //     })
      //   }
      //   if (UNITJS.judgeHaveRight(this.GLOBAL.DATA_ANALYSIS)) {
      //     // 数据分析权限
      //     this.tabbars.push({
      //       title: '报表',
      //       to: {
      //         name: 'Chart'
      //       },
      //       // icon: 'chart-trending-o'
      //       active: 2,
      //       icon: {
      //         active: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive22.png',
      //         inactive: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive2.png'
      //       }
      //     })
      //   }
      //   this.tabbars.push({
      //     title: '我的',
      //     to: {
      //       name: 'Mine'
      //     },
      //     // icon: 'user-o'
      //     active: 3,
      //     icon: {
      //       active: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive33.png',
      //       inactive: 'https://eage-community.oss-cn-hangzhou.aliyuncs.com/dai%2Factive3.png'
      //     }
      //   })
    }
  }
</script>
<style lang="less">
  .van-toast__icon {
    font-size: 80px !important;
  }

  .van-toast {
    font-size: 36px !important;
    min-width: 200px !important;
    min-height: 200px !important;
  }

</style>
