<template>
    <van-tabbar route v-model="active" @change="handleChange" :fixed="fixed" :border="border" active-color="#2B583F"
        inactive-color="#A1AEAC" class="menu-list">
        <van-tabbar-item v-for="(item, index) in data" :to="item.to" :key="index">
            <!-- {{ item.title }} -->
            <span class="tab-name">{{ item.title }}</span>
            <template #icon="data">
                <img :src="[data.active ? item.icon.active : item.icon.inactive]" class="tab-img" />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>
<script>
    export default {
        name: 'TabBar',
        props: {
            defaultActive: {
                type: Number,
                default: 0
            },
            data: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                active: this.defaultActive,
                fixed: false,
                border: false
            }
        },
        methods: {
            handleChange(value) {
                this.$emit('change', value)
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    /* .my-tabbar {
        height: 68px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px 0px rgba(224, 224, 224, 0.5);

        .tab-name {
            font-size: 12px;
            font-weight: 500;
        }

        .tab-img {
            width: 24px;
            height: 24px;
        }
    } */
    .menu-list {
        height: 410px;
        border: none;
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        background: transparent;

    }

    /deep/.van-tabbar-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        background: #FFFFFF !important;
        border-radius: 0px 24px 24px 0px !important;
        margin-bottom: 20px;
        padding: 15px 0px;

        .tab-img {
            width: 50px;
            height: 50px;
            margin-right: 8px;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .tab-name {
            font-weight: 500;
            font-size: 25px;
        }
    }

</style>
