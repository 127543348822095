import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import VConsole from 'vconsole'
import dayjs from 'dayjs'
import api from './api'
import utils from './utils'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Toast
} from 'vant';
// import 'amfe-flexible'
import '@/assets/style.css'
import {
  Dialog
} from 'vant'
import {
  Message
} from 'element-ui'
import {
  helper
} from './utils/urlhelper'
import Vuex from 'vuex'
Vue.use(Vuex)
Vue.use(Vant)
Vue.use(ElementUI)
if (process.env.VUE_APP_ENV === 'test') {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}


// 身份证号码过滤器
Vue.filter('hideBirthday', function (idNumber) {
  if (!idNumber) return ''
  if (idNumber.length !== 15 && idNumber.length !== 18) return idNumber
  return idNumber.replace(/^(\d{6})(\d{8})(\d{4})$/, '$1********$3')
})
// 联系电话过滤器
Vue.filter('hidePhone', function (phone) {
  if (!phone) return ''
  if (phone.length !== 11) return phone
  return phone.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1****$3')
})
// Vue.prototype.$state = mapState
// Vue.prototype.$mutaion = mapMutations
// Vue.prototype.$action = mapActions
// Vue.prototype.$get = mapGetters
Vue.prototype.$api = api
Vue.prototype.$utils = utils
Vue.prototype.$dayjs = dayjs
Vue.prototype.$showDialog = Dialog
Vue.prototype.$message = Message
Vue.prototype.$toast = Toast
// Vue.component('BottomWrapper', () => import('@/components/BottomWrapper.vue'))
// Vue.component('BlockButton', () => import('@/components/BlockButton.vue'))
Vue.component('Search', () => import('@/components/search.vue'))
Vue.component('TitleImage', () => import('@/components/TitleImage.vue'))
Vue.component('Container', () => import('@/components/MarginContainer.vue'))
Vue.component('UploadImg', () => import('@/components/UploadImg.vue'))

Vue.config.productionTip = false

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  const token = store.state.data.token
  console.log(from)
  console.log(to)
  document.title = to.meta.title
  if (to.path === '/activity-list' || to.path === '/register1' || to.path === '/user') {
    if (to.path === '/register1' || token) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})
router.afterEach((to, from) => {

  // next()
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
